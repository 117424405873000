export default {
  "urls": [
    "audio/sounds.m4a",
    "audio/sounds.ogg"
  ],
  "sprite": {
    "gameOver_1": [
      0,
      4488
    ],
    "gameOver_2": [
      6000,
      4488
    ],
    "gameOver_3": [
      12000,
      4488
    ],
    "gameOver_4": [
      18000,
      3384.0000000000005
    ],
    "gameOver_5": [
      23000,
      3384.0000000000005
    ],
    "gameOver_6": [
      28000,
      3384.0000000000005
    ],
    "gameOver_7": [
      33000,
      3384.0000000000005
    ],
    "gameOver_8": [
      38000,
      3384.0000000000005
    ],
    "levelSound_3": [
      43000,
      1320.0000000000002
    ],
    "ribbon_1": [
      46000,
      2640.0000000000005
    ],
    "ribbon_10": [
      50000,
      2712.000000000003
    ],
    "ribbon_11": [
      54000,
      2712.000000000003
    ],
    "ribbon_12": [
      58000,
      2712.000000000003
    ],
    "ribbon_2": [
      62000,
      2712.000000000003
    ],
    "ribbon_3": [
      66000,
      2712.000000000003
    ],
    "ribbon_4": [
      70000,
      2712.000000000003
    ],
    "ribbon_5": [
      74000,
      2712.000000000003
    ],
    "ribbon_6": [
      78000,
      2712.000000000003
    ],
    "ribbon_7": [
      82000,
      2712.000000000003
    ],
    "ribbon_8": [
      86000,
      2712.000000000003
    ],
    "ribbon_9": [
      90000,
      2712.000000000003
    ],
    "roundStart": [
      94000,
      1992.0000000000045
    ],
    "roundWon_sh1": [
      97000,
      3048.000000000002
    ]
  }
}
